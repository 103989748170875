import logo from "./logo.svg";
import "./App.css";
import { Router, Routes, Route } from "react-router-dom";
//import "react-calendar/dist/Calendar.css";

import HorizontalForm from "./components/form";
function App() {
  return (
    <div className="App">
     
      <HorizontalForm/>
    
     
    </div>
  );
}

export default App;
